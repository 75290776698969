import Modal from '../Modal';
import {
	StyledBox,
	StyledColumn,
	StyledRow,
} from '../../../../../common/styledCommonComponents';
import React, { useEffect, useReducer, useState } from 'react';
import {
	useFixPatientMutation,
	useUnfixPatientMutation,
	useLazyGetPatientDataQuery,
	useUpdatePatientStatusMutation,
	useLazyGetPatientScheduleQuery,
	useUpdatePatientScheduleMutation,
} from '../../../../../redux/api/patientApi';
import { useTheme } from 'styled-components';
import StyledText, {
	StyledH5,
} from '../../../../../common/components/Text/styles';
import Dropdown from '../../../../../common/components/Dropdown';
import Button from '../../../../../common/components/Button';
import {
	useOncoDispatch,
	useOncoSelector,
} from '../../../../../hooks/redux-hook';
import { statusMap } from '../../../../PatientProfileCard/components/PatientStatus';
import { StyledCircularProgress } from '../../../../CustomCircularProgress/styles';
import Accordion from '../../../../../common/components/Accordion';
import { Switch } from '../../../../../common/components/Switch';
import { FrequencyOptions } from '../../../../FrequencyOptions';
import { setDisable } from '../../../../../redux/slices/utilsSlice';
import { FrequencyHeader } from './components/FrequencyHeader';

interface ProfileConfigModalProps {
	handleOnClose: () => void;
}

function configReducer(state: any, action: any) {
	switch (action.type) {
		case 'change_status': {
			return {
				status: action.newStatus,
				isFixed: state.isFixed,
			};
		}
		case 'change_fixed': {
			return {
				status: state.status,
				isFixed: action.isFixed,
			};
		}
		case 'reset': {
			return {
				status: undefined,
				isFixed: undefined,
			};
		}
	}
	throw Error('Unknown action: ' + action.type);
}

const ProfileConfigModal = ({ handleOnClose }: ProfileConfigModalProps) => {
	const theme = useTheme();

	const oncoDispatch: any = useOncoDispatch();

	const {
		patientId,
		medicalStatus,
		disable,
		dailyConfiguration,
		weeklyConfiguration,
	} = useOncoSelector((state: any) => state.utilsSlice);

	const [updatePatientStatus] = useUpdatePatientStatusMutation();
	const [fixPatient] = useFixPatientMutation();
	const [unfixPatient] = useUnfixPatientMutation();
	const [updateSchedule] = useUpdatePatientScheduleMutation();
	const [refetchPatientData, { data }] = useLazyGetPatientDataQuery();
	const [refetchPatientSchedule, { data: patientSchedule }] =
		useLazyGetPatientScheduleQuery();

	const [updateLoading, setUpdateLoading] = useState<boolean>(false);
	const [status, setStatus] = useState(medicalStatus);

	const [state, dispatch] = useReducer(configReducer, {
		status: undefined,
		isFixed: undefined,
	});

	useEffect(() => {
		refetchPatientData(patientId);
		setStatus(state.status ?? medicalStatus ?? data?.medicalStatus);
	}, [medicalStatus, data, state]);

	useEffect(() => {
		refetchPatientSchedule(patientId);
	}, []);

	function handleChangeStatus(name: any, item: any) {
		dispatch({
			type: 'change_status',
			newStatus: item,
		});
		oncoDispatch(setDisable(false));
	}

	const handleOnCheck = () => {
		dispatch({
			type: 'change_fixed',
			isFixed: true,
		});
		oncoDispatch(setDisable(false));
	};

	const handleOnUncheck = () => {
		dispatch({
			type: 'change_fixed',
			isFixed: false,
		});
		oncoDispatch(setDisable(false));
	};
	const handleOnUpdate = () => {
		setUpdateLoading(true);
		if (state.status !== undefined) {
			updatePatientStatus({
				patientId,
				newStatus: statusMap[state.status],
			});
		}
		if (state.isFixed !== undefined) {
			if (state.isFixed) {
				fixPatient(patientId);
			} else {
				unfixPatient(patientId);
			}
		}
		updateSchedule({
			patientId,
			body: {
				formId: 'b7945eb2-8342-4ab3-9f6f-dfea14999e7a',
				configuration: JSON.stringify(weeklyConfiguration),
			},
		});
		updateSchedule({
			patientId,
			body: {
				formId: '22677030-b1ab-4ec3-8b6c-5cbeb2e39a15',
				configuration: JSON.stringify(dailyConfiguration),
			},
		});
		dispatch({ type: 'reset' });
		setUpdateLoading(false);
		oncoDispatch(setDisable(true));
	};

	return (
		<Modal
			width={'680'}
			// height={552}
			onClose={handleOnClose}
			header={
				<StyledColumn
					css={{
						alignItems: 'flex-start',
						gap: '4px',
						alignSelf: 'stretch',
					}}
				>
					<StyledH5 css={{ color: '#333' }}>Configuraciones</StyledH5>
					<StyledText variant="body2" css={{ color: '#333' }}>
						Aquí podrás configurar los estados de las encuestas y el
						estado general del paciente
					</StyledText>
				</StyledColumn>
			}
			body={
				data === undefined ? (
					<StyledBox
						css={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '100%',
							width: '100%',
						}}
					>
						<StyledCircularProgress />
					</StyledBox>
				) : (
					<StyledColumn
						css={{
							width: '100%',
							rowGap: '32px',
						}}
					>
						<StyledColumn
							css={{
								rowGap: '16px',
								alignItems: 'flex-start',
								alignSelf: 'stretch',
							}}
						>
							<StyledText variant="body1Bold" css={{ color: '#333' }}>
								Estado de la cuenta
							</StyledText>
							<StyledRow
								css={{
									justifyContent: 'space-between',
									alignItems: 'center',
									alignSelf: 'stretch',
									height: '43px',
								}}
							>
								<StyledText variant="body1" css={{ color: '#333' }}>
									Estado
								</StyledText>
								<Dropdown
									width="241px"
									height="43px"
									value={status}
									onChange={handleChangeStatus}
									name={''}
									options={statusMap}
								/>
							</StyledRow>
							<StyledRow
								css={{
									justifyContent: 'space-between',
									alignItems: 'center',
									alignSelf: 'stretch',
									height: '43px',
								}}
							>
								<StyledText variant="body1" css={{ color: '#333' }}>
									Fijado
								</StyledText>
								<Switch
									checked={data?.isFixed}
									onCheck={() => handleOnCheck()}
									onUncheck={() => handleOnUncheck()}
								/>
							</StyledRow>
						</StyledColumn>
						<StyledColumn css={{ rowGap: '16px' }}>
							<StyledText variant="body1Bold" css={{ color: '#333' }}>
								Frecuencia de encuestas
							</StyledText>
							<StyledRow
								css={{
									padding: '8px 16px',
									alignItems: 'center',
									gap: '24px',
									alignSelf: 'stretch',
									borderRadius: '12px',
									border: `1px solid ${theme.gray100}`,
								}}
							>
								<Accordion
									header={
										<FrequencyHeader
											label={'Estado General'}
											configuration={dailyConfiguration}
										/>
									}
								>
									{patientSchedule && (
										<StyledBox css={{ marginTop: '16px' }}>
											<FrequencyOptions
												type="daily"
												data={dailyConfiguration}
											/>
										</StyledBox>
									)}
								</Accordion>
							</StyledRow>
							<StyledRow
								css={{
									padding: '8px 16px',
									alignItems: 'center',
									alignSelf: 'stretch',
									borderRadius: '12px',
									border: `1px solid ${theme.gray100}`,
								}}
							>
								<Accordion
									header={
										<FrequencyHeader
											label={'Calidad de Vida'}
											configuration={weeklyConfiguration}
										/>
									}
								>
									{patientSchedule && (
										<StyledBox css={{ marginTop: '16px' }}>
											<FrequencyOptions
												type="weekly"
												data={weeklyConfiguration}
											/>
										</StyledBox>
									)}
								</Accordion>
							</StyledRow>
						</StyledColumn>
						<StyledRow
							css={{
								justifyContent: 'flex-end',
								alignItems: 'flex-end',
								gap: '16px',
								alignSelf: 'stretch',
							}}
						>
							<Button
								width="130px"
								height="42px"
								variant="outlined"
								size="medium"
								onClick={handleOnClose}
							>
								{' '}
								Cancelar{' '}
							</Button>
							<Button
								width="130px"
								height="42px"
								size="medium"
								disabled={disable ?? true}
								onClick={handleOnUpdate}
								pending={updateLoading}
							>
								{' '}
								Actualizar{' '}
							</Button>
						</StyledRow>
					</StyledColumn>
				)
			}
		/>
	);
};

export default ProfileConfigModal;
