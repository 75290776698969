import React, { ReactNode, useRef } from 'react';
import {
	StyledBox,
	StyledColumn,
	StyledRow,
} from '../../../../../common/styledCommonComponents';
import CloseIcon from '../../../../../assets/CloseIcon';
import { useTheme } from 'styled-components';
import { useOnClickOutside } from 'usehooks-ts';

interface ModalProps {
	header: ReactNode;
	body: ReactNode | undefined;
	onClose: () => void;
	isLoading?: ReactNode;
	width?: string | number;
}

const Modal = ({ header, body, onClose, isLoading, width }: ModalProps) => {
	const theme = useTheme();

	const ref = useRef(null);

	useOnClickOutside(ref, onClose);

	return (
		<StyledColumn
			ref={ref}
			css={{
				boxSizing: 'border-box',
				position: 'fixed',
				display: 'flex',
				flexDirection: 'column',
				width: width ?? '850px',
				zIndex: 11,
				backgroundColor: 'white',
				borderRadius: '20px',
				maxHeight: '90vh',
				overflowY: 'hidden',
				overflow: 'auto',
				padding: '24px 32px',
				gap: 24,
			}}
		>
			<StyledRow
				css={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<StyledBox
					onClick={onClose}
					css={{
						cursor: 'pointer',
						position: 'absolute',
						top: 24,
						right: 24,
					}}
				>
					<CloseIcon color={theme.gray200} width={20} height={20} />
				</StyledBox>
				{!isLoading && header}
			</StyledRow>
			{!!body && (
				<StyledColumn
					css={{
						maxHeight: 'calc(90vh - 90px)',
						alignItems: 'flex-start',
						alignSelf: 'stretch',
						gap: '32px',
					}}
				>
					{isLoading ?? body}
				</StyledColumn>
			)}
		</StyledColumn>
	);
};

export default Modal;
